import { caliperDetailsService } from "@/business/caliperDetailsService";
import BreadcrumbComponent from "@/components/commons/breadcrumb/BreadcrumbComponent.vue";
import { configurationService } from "@/business/configurationService";

export default {
  components: {
    BreadcrumbComponent
  },
  name: 'CaliperDetailsHoursComponent',
  data() {
    return {
      loading: true,
      hoursDetailsCharts: [],
      daysIndex: [],
      hoursChartDetails: [],
      mapRenderKey: (Math.random() + 1).toString(36).substring(2),
      initPosition: null,
      selectedReferences: [],
      model: {
        charts: [],
        maps: []
      }
    }
  },
  props: {
    thingDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
    onClickChartDays(data) {
      const index = data._index;
      const detailsHours = caliperDetailsService.getDetailsHoursChart(this.thingDetails.data, this.thingDetails.hardware, this.daysIndex[index]);
      if(this.model.maps.length > 0) this.model.maps[0].name = this.$i18n.t("caliperSummary.map.name");
      this.hoursChartDetails = detailsHours.dates;
      this.hoursDetailsCharts.cleanAndUpdate(detailsHours.charts);
    },
    async onClickChartHours(data) {
      const index = data._index;
      const hourSelected = this.hoursChartDetails[index];
      const map = await caliperDetailsService.getHoursMap(this.thingDetails.data.idThing, this.thingDetails.data.thingName, hourSelected.timestamp, hourSelected.timestamp + 3599, true);
      this.model.maps.cleanAndUpdate(map.maps);
      this.initPosition = map.initPosition;
    },
    onClickChartHoursDetails() {
    },
    onMapClick() {
    },
    async filtersChanged(data) {
      await configurationService.save("product.caliperDetails.hours.map", { currentLayer: data.currentLayer });
    },
    async positionAndZoomChanged(){
      // console.log(data)
    }
  },
  created() {
  },
  async mounted() {
    this.loading = true;
    if (this.thingDetails && this.thingDetails.data && this.thingDetails.data.detailsBuckets.length > 0) {
      const report = await caliperDetailsService.generateDetailsReport(this.thingDetails.data, this.thingDetails.hardware);
      this.daysIndex = report.daysIndex;
      const detailsHours = caliperDetailsService.getDetailsHoursChart(this.thingDetails.data, this.thingDetails.hardware, this.daysIndex[0]);
      const mapData = await caliperDetailsService.getHoursMap(this.thingDetails.data.idThing, this.thingDetails.data.thingName, this.daysIndex[0].timestamp, this.daysIndex[0].timestamp + 3599);
      this.initPosition = mapData.initPosition;
      this.hoursChartDetails = detailsHours.dates;
      this.hoursDetailsCharts.cleanAndUpdate(detailsHours.charts);
      this.model.charts.cleanAndUpdate(report.charts);
      this.model.maps.cleanAndUpdate(mapData.maps);
    }
    this.loading = false;
  }
}
