import { caliperDetailsService } from "@/business/caliperDetailsService";

export default {
  components: {
  },
  name: 'CaliperDetailsMapComponent',
  data() {
    return {
      chartId: "initId",
      model: {
        maps: []
      },
      initPosition: null,
      selectedReferences: [],
      loading: false
    }
  },
  props: {
    thingDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
    onMapClick() {
      console.log("map clicked");
    },
    filtersChanged() {
      // console.log("filtersChanged");
    }
  },
  created() {

  },
  async mounted() {
    this.loading = true;
    if (this.thingDetails && this.thingDetails.data && this.thingDetails.data.detailsBuckets.length > 0) {
      const mapData = await caliperDetailsService.generateMapPressure(this.thingDetails, this.thingDetails.data.idThing);
      this.model.maps = mapData.maps;
      this.initPosition = mapData.initPosition;
    }
    this.loading = false;
  }
}
