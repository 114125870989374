import http from '@/middleware';

export const caliperApi = {
    async getSummaryReportByDate(filters) {
        const result = await http.post('/api/caliper/reportCustomTime', filters);
        return result.data;
    },

    async pressureThingDataByDate(filters) {
        const result = await http.post('/api/caliper/pressureThingDataByDate', filters);
        return result.data;
    },

    async getSummaryReportFixedRange(filters) {
        const result = await http.post("/api/caliper/reportFixedRange", filters);
        return result.data;
    },

    async getThingPositions(idThing, from, to) {
        const result = await http.post("/api/caliper/positionByIdThing", { idThing, from, to });
        return result.data;
    }
}