class ToolsService {
    parseSecondsToString(time, hoursChart = false) {
        const hours = parseInt(time / 3600);
        let minutes = parseInt((time % 3600) / 60);
        const seconds = parseInt(time % 60);
        if (hoursChart) {
            if (time == 3600) minutes = 60;
            return `${minutes}m ${seconds}s`;
        }
        return `${hours}h ${minutes}m ${seconds}s`;
    }
}

export default new ToolsService();