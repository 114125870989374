import {breadcrumbService} from "@/business/breadcrumb.service";
import {caliperDetailsService} from "@/business/caliperDetailsService";
import {configurationService} from "@/business/configurationService";
import {filterService} from "@/business/filterService";
import CaliperDetailsDistanceComponent
  from "@/components/caliper/caliperDetails/distance/CaliperDetailsDistanceComponent.vue";
import CaliperDetailsHoursComponent from "@/components/caliper/caliperDetails/hours/CaliperDetailsHoursComponent.vue";
import CaliperDetailsMapComponent from "@/components/caliper/caliperDetails/map/CaliperDetailsMapComponent.vue";
import CaliperDetailsPressureComponent
  from "@/components/caliper/caliperDetails/pressure/CaliperDetailsPressureComponent.vue";
import BreadcrumbComponent from "@/components/commons/breadcrumb/BreadcrumbComponent.vue";
import NoDataAvailableComponent from '@/components/commons/no-data-available/NoDataAvailableComponent.vue';
import FilterComponent from '@/components/filter/FilterComponent.vue';
import i18n from "@/i18n";
import store from "@/store/store";
import {mapActions, mapMutations} from "vuex";
import {CALIPER_REPORT_TYPES} from "@/constants/caliperConstants";


export default {
  components: {
    BreadcrumbComponent,
    CaliperDetailsHoursComponent,
    CaliperDetailsPressureComponent,
    CaliperDetailsDistanceComponent,
    CaliperDetailsMapComponent,
    FilterComponent,
    NoDataAvailableComponent
  },
  name: 'CaliperDetailsComponent',
  data() {
    return {
      init: true,
      loading: false,
      thingSelected: null,
      reportData: null,
      mapDateSelectors: [],
      mapDateSelected: null,
      reportKey: (Math.random() + 1).toString(36).substring(2),
      returnSummaryButton: {
        id: 'back',
        icon: 'arrow_back',
        show: () => { return false },
        disable: () => { return false },
        function: this.backToSummary.bind(this)
      },
      thingDetails: {
        data: null,
        hardware: '01',
        filters: null
      },
      showSelector: false,
      showFilters: false,
      maxQuantityLimit: 100,
      orderFilterWorstBestPropertyName: i18n.t("filters.tabOrder.worstBest"),
      dataForRangeFilter: {
        showRangeData: false,
        minLimit: 0,
        maxLimit: 1000,
        propertyName: "ppty",
      },
      filters: filterService.getFilterModel(),
      model: {
        charts: [],
        maps: [],
        table: {
          headers: [],
          data: []
        }
      },
      selectedTab: 'tab-hours',
      breadcrumbButtons: [],
      tabs: [
        {
          id: 'tab-hours',
          name: i18n.t('caliperDetails.breadcrumbTab.hours')
        },
        {
          id: 'tab-distance',
          name: i18n.t('caliperDetails.breadcrumbTab.distance')
        },
        {
          id: 'tab-pressure',
          name: i18n.t('caliperDetails.breadcrumbTab.pressure')
        },
        {
          id: 'tab-map',
          name: i18n.t('caliperDetails.breadcrumbTab.map')
        }
      ],
      hardwareList: []
    }
  },
  computed: {
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar"
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    closeFilter() {
      this.showFilters = false;
    },
    async saveFilterChanges() {
      this.loading = true;
      this.thingSelected = null;
      this.mapDateSelected = null;
      configurationService
        .save(CALIPER_REPORT_TYPES.DETAILS, { filters: this.filters })
        .then(() => {
        })
        .catch(() => {
        });
      this.closeFilter();
      this.cleanData();
      try {
        const result = await caliperDetailsService.getDetailsReport(this.filters);
        if (result && result !== 'ok') {
          result.charts = { charts: result.charts };
          result.filters = this.filters;
          result.reportData = result.reportData.data;
          this.setReport(result);
        } else {
          this.showReportDefer({ updateFunction: this.setReportByKey.bind(this) })
        }
        this.selectedTab = 'tab-hours';
        this.returnSummaryButton.show = () => { return false };
      } finally {
        this.loading = false;
        this.init = false;
      }
    },
    async setReportByKey(data) {
      this.init = false;
      const report = await caliperDetailsService.getReportByKey(data);
      if (report.reportData.length > 0) {
        this.setReport(report);
      }
    },
    cleanData() {
      this.thingDetails.data = null;
      this.reportKey = (Math.random() + 1).toString(36).substring(2);
    },
    selector() {
      this.showFilters = !this.showFilters;
    },
    onClickChart() {
    },
    // eslint-disable-next-line no-unused-vars
    async changedDetails(selectedItem) {
      this.thingDetails.data = this.reportData.find(thing => thing.idThing === selectedItem.idThing.idThing);
      this.thingDetails.hardware = selectedItem.hardwareId && selectedItem.hardwareId.hardwareId;
      if (selectedItem.eventKey === 'daysSelector') {
        this.mapDateSelected = selectedItem.daysSelector;
        this.thingDetails.filters.mapDate = this.mapDateSelected;
      }
      if (selectedItem.eventKey === 'idThing' && selectedItem.hardwareId) {
        const idthingSelected = selectedItem.idThing.idThing;
        caliperDetailsService.updateHardwareSelector(
          this.hardwareList[idthingSelected],
          {
            hardwareId: this.hardwareList[idthingSelected][0],
            hardwareName: i18n.t('caliperDetails.filters.hardware.' + this.hardwareList[idthingSelected][0])
          }
        );
      }
      this.reportKey = (Math.random() + 1).toString(36).substring(2);
    },
    tabSelected(selected) {
      setTimeout(() => {
        const resizeEvent = new Event('resize');
        window.dispatchEvent(resizeEvent);
      }, 1);
      if (selected === "tab-map" || selected === 'tab-pressure') {
        breadcrumbService.cleanById('daysSelector');
        breadcrumbService.addDaysFilters(this.mapDateSelectors);
        if (!this.mapDateSelected) {
          this.mapDateSelected = this.mapDateSelectors[0];
          store.dispatch("breadcrumb/setSelectedItem", {
            daysSelector: this.mapDateSelected
          });
        }
      } else {
        breadcrumbService.cleanById('daysSelector');
      }
    },
    backToSummary() {
      this.$router.push({
        name: "caliperSummary",
        params: { filters: this.filters, reportData: this.reportData }
      });
    },
    async setReport(report) {
      this.model.charts.cleanAndUpdate(report.charts.charts);
      this.hardwareList = report.hardwareList;
      this.filters = JSON.parse(JSON.stringify(report.filters));
      this.reportData = report.reportData;
      store.dispatch("breadcrumb/cleanBreadcrumb");
      let thingData = this.thingSelected ? report.reportData.find(data => data.thingName === this.thingSelected) : report.reportData[0];
      this.thingSelected = thingData.thingName;
      caliperDetailsService.loadThingSelector(report.reportData, {
        idThing: thingData.idThing,
        thingName: thingData.thingName
      });
      caliperDetailsService.loadHardwareSelector(
        this.hardwareList,
        thingData.idThing,
        {
          hardwareId: '01',
          hardwareName: 'Circuito 1'
        }
      );
      this.thingDetails.data = report.reportData[0];
      this.thingDetails.hardware = '01';
      this.thingDetails.filters = report.filters;
      this.thingDetails.filters.dates = caliperDetailsService.getFromToDate(report.filters);
      this.reportKey = (Math.random() + 1).toString(36).substring(2);
      breadcrumbService.setSummaryDetailsByFilters(this.filters, 'CALIPER_DETAILS_REPORT');
      this.mapDateSelectors = caliperDetailsService.getDaysByFromTo(this.thingDetails.filters.dates.from, this.thingDetails.filters.dates.to);
      this.thingDetails.filters.mapDate = this.mapDateSelectors[0];
    }
  },
  created() {
    store.dispatch("breadcrumb/cleanBreadcrumb");
    this.breadcrumbButtons.push(
      this.returnSummaryButton,
      {
        id: 'setting-btn',
        icon: 'settings',
        show: () => { return true },
        disable: () => { return false },
        function: this.selector.bind(this)
      }
    );
  },
  async mounted() {
    const report = await caliperDetailsService.initReport(this.$route);
    if (report && report !== 'ok') {
      if (report.fromSummary) {
        this.thingSelected = report.thingName;
        this.returnSummaryButton.show = () => { return true };
      }
      if (report.reportData.length > 0) {
        this.filters = report.filters;
        this.setReport(report);
      } else {
        this.init = false;
      }
    } else {
      this.cleanData();
      this.filters = filterService.getFilterModel();
      this.showFilters = true;
    }

  }
}
