import HighchartComponent from "@/components/commons/highchart/HighchartComponent.vue";
import { caliperDetailsService } from "@/business/caliperDetailsService";

export default {
  components: {
    HighchartComponent
  },
  name: 'CaliperDetailsPressureComponent',
  data() {
    return {
      loading: false,
      chartId: "initId",
      chart: {
        chart: {
          renderTo: 'HighchartComponentId_' + (Math.random() + 1).toString(36).substring(7),
          reflow: true
        },
        exporting: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        lang: {
          noData: ''
        }
      }
    }
  },
  props: {
    thingDetails: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
    onClickChart(event) {
      console.log("onClickChart", event);
    }
  },
  created() {

  },
  async mounted() {
    this.loading = true;
    if (this.thingDetails && this.thingDetails.data && this.thingDetails.data.detailsBuckets.length > 0) {
      const chart = await caliperDetailsService.generateChartPressure(this.thingDetails, this.thingDetails.filters, this.thingDetails.data.idThing, this.thingDetails.hardware, this.thingDetails.data.caliperConfig);
      this.chart = chart;
      this.chartId = 'HighchartComponentId_' + (Math.random() + 1).toString(36).substring(7);
    }
    this.loading = false;
  }
}
