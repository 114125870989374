import { caliperApi } from "@/api/caliper.api";
import reportStorageApi from '@/api/report-storage.api';
import { configurationService } from "@/business/configurationService";
import { CALIPER_CHART_DETAILS_TEMPLATE } from "@/constants/caliperConstants";
import i18n from "@/i18n";
import store from "@/store/store";
import { TimeRanges, unitParser } from "@colven/common-domain-lib/lib";
import * as deepcopy from "deepcopy";
import moment from "moment";
import { ADJUSTMENT_DEFAULT_CONFIG, ALERT_COLORS, CALIPER_CHART_DATASET_TEMPLATE, CALIPER_DETAILS_CHART_PRESSURE, CALIPER_DETAIL_MARK_MAP_POINT_JSON_TEMPALTE, CALIPER_MAP_JSON_TEMPLATE, CALIPER_MAP_POINT_JSON_TEMPALTE, CALIPER_MAP_REFERENCE_TEMPLATE, CALIPER_PRESSURE_MAP_JSON_TEMPLATE, CHART_TYPE_COLORS } from "../constants/caliperConstants";
import { parseDateWithTimezone, parseTimeWithTimezone, processFilters } from "../tools/functions";
import toolsService from "./toolsService";

export const caliperDetailsService = (function () {
    'use strict';

    async function initReport(router) {
        let result = null;
        if (router.params && Object.keys(router.params).length != 0) {
            const filters = router.params.filters;
            const reportData = router.params.reportData;
            const thingName = router.params.thingName;
            const hardwareList = getHardwareAbleMap(reportData);
            result = await generateDetailsReport(reportData, hardwareList[0]);
            result.filters = filters;
            result.fromSummary = true;
            result.thingName = thingName;
            result.hardwareList = hardwareList;
        }
        if (router.query && router.query.key) {
            result = getReportByKey(router.query.key);
        }
        return result;
    }

    async function getReportByKey(key) {
        let result = null;
        const reportData = await reportStorageApi.getReport(key);
        const hardwareList = getHardwareAbleMap(reportData.data);
        result = await generateDetailsReport(reportData.data, hardwareList[0]);
        result.filters = reportData.filters;
        result.hardwareList = hardwareList;
        return result;
    }

    async function getDetailsReport(filters) {
        const filterData = processFilters(filters);
        filterData.reportType = "CALIPER_DETAILS";
        filterData.originalFilters = filters;
        let reportData = null;
        if (filterData.reporFixedKey === TimeRanges.CUSTOM) {
            await caliperApi.getSummaryReportByDate(filterData);
            return "ok";
        } else {
            const data = await caliperApi.getSummaryReportFixedRange(filterData);
            reportData = {
                data: data,
                filters: filterData,
            }
            const hardwareList = getHardwareAbleMap(data);
            const result = await generateDetailsReport(reportData, hardwareList[0]);
            return { ...result, hardwareList };
        }
    }

    function getHardwareAbleMap(data) {
        const result = {};
        if (data && data.length) {
            for (const thingData of data) {
                if (thingData.caliperConfig && thingData.caliperConfig.pressure && thingData.caliperConfig.pressure.hardwareNumber) {
                    result[thingData.idThing] = Object.keys(thingData.caliperConfig.pressure.hardwareNumber);
                }
            }
        }
        return result;
    }

    async function generateDetailsReport(reportData, device) {
        const charts = getDetailsCharts(reportData, device);
        return {
            charts: charts.charts,
            daysIndex: charts.daysIndex,
            reportData: reportData
        }
    }

    async function generateDistanceDetailsReport(reportData, device) {
        const charts = getDistanceDetailsCharts(reportData, device);
        return {
            charts: charts.charts,
            daysIndex: charts.daysIndex,
            reportData: reportData
        }
    }

    async function getHoursMap(idThing, thingName, from, to, showDate = false) {
        const map = deepcopy(CALIPER_MAP_JSON_TEMPLATE);
        map.id = Math.random() * 1000000000;
        const mapConfig = await configurationService.get("product.caliperDetails.hours.map");
        const positions = await caliperApi.getThingPositions(idThing, from, to);
        const mapCurrentLayer = "Google Road";
        let initPosition = {
            lat: null,
            long: null,
            zoom: 12,
            currentLayer: mapCurrentLayer
        }
        if (mapConfig?.data?.currentLayer) {
            initPosition.currentLayer = mapConfig.data.currentLayer;
        }
        const reference = deepcopy(CALIPER_MAP_REFERENCE_TEMPLATE);
        reference.name = thingName;
        reference.title = thingName;
        map.groups[0].references.push(reference);
        map.groups[0].name = i18n.t('caliperDetails.hours.map.name');
        map.groups[0].title = i18n.t('caliperDetails.hours.map.title');
        if (showDate) map.name = map.name + " - " + timestampToString(from);
        const points = map.groups[0].series[0].points;
        if (positions && positions.length > 0) {
            for (const position of positions) {
                const caliperPoint = deepcopy(CALIPER_MAP_POINT_JSON_TEMPALTE);
                caliperPoint.id = idThing;
                caliperPoint.lat = position.lat;
                caliperPoint.lng = position.lng;
                caliperPoint.value = thingName;
                points.push(caliperPoint);
                // initPosition.lat = position.lat;
                // initPosition.long = position.lng;
            }
        }
        setFlagAndTrackMarkers(points);
        return {
            maps: [map],
            initPosition
        };
    }

    function setFlagAndTrackMarkers(points) {
        if (points.length > 0) {
            points[0].marker = {
                title: "",
                type: "fa-font",
                ref: "fa-flag",
                size: 40
            };
        }
        if (points.length > 1) {
            points[points.length - 1].marker = {
                title: "",
                type: "img",
                ref: "truck-icon.svg",
                size: 40
            }
        }
    }

    async function getHoursDistanceMap(idThing, thingName, from, to, showDate = false) {
        const map = deepcopy(CALIPER_MAP_JSON_TEMPLATE);
        map.id = Math.random() * 1000000000;
        const mapConfig = await configurationService.get("product.caliperDetails.distance.map");
        const positions = await caliperApi.getThingPositions(idThing, from, to);
        const mapCurrentLayer = "Google Road";
        let initPosition = {
            lat: null,
            long: null,
            zoom: 12,
            currentLayer: mapCurrentLayer
        }
        if (mapConfig?.data?.currentLayer) {
            initPosition.currentLayer = mapConfig.data.currentLayer;
        }
        const reference = deepcopy(CALIPER_MAP_REFERENCE_TEMPLATE);
        reference.name = thingName;
        reference.title = thingName;
        map.groups[0].references.push(reference);
        map.groups[0].name = i18n.t('caliperDetails.distance.map.name');
        map.groups[0].title = i18n.t('caliperDetails.distance.map.title');
        if (showDate) map.name = map.name + " - " + timestampToString(from);
        const points = map.groups[0].series[0].points;
        if (positions && positions.length > 0) {
            for (const position of positions) {
                const caliperPoint = deepcopy(CALIPER_MAP_POINT_JSON_TEMPALTE);
                caliperPoint.id = idThing;
                caliperPoint.lat = position.lat;
                caliperPoint.lng = position.lng;
                caliperPoint.value = thingName;
                map.groups[0].series[0].points.push(caliperPoint);
            }
        }
        setFlagAndTrackMarkers(points);
        return {
            maps: [map],
            initPosition
        };
    }

    function secondsToString(value) {
        let secondsConversion = Math.trunc(value % 60);
        secondsConversion = secondsConversion < 10 ? "0" + secondsConversion : secondsConversion
        let minutesConversion = Math.trunc((value / 60) % 60);
        minutesConversion = minutesConversion < 10 ? "0" + minutesConversion : minutesConversion
        let hoursConversion = Math.trunc((value / (60 * 60)));
        hoursConversion = hoursConversion < 10 ? "0" + hoursConversion : hoursConversion
        return `${hoursConversion}:${minutesConversion}:${secondsConversion}`;
    }

    function getDetailsCharts(data, device) {
        // CHART init
        const chart = deepcopy(CALIPER_CHART_DETAILS_TEMPLATE);
        chart.id = (Math.random() + 1).toString(36).substring(2);
        chart.name = i18n.t(chart.name);
        chart.options.scales.yAxes[0].ticks.stepSize = 1800;
        setDetailsChartOptions(chart);
        const dates = getDateColums(data, device);
        const labels = [];
        const daysIndex = [];
        dates.forEach(date => {
            labels.push(date.date);
            daysIndex.push({ date: date.date, timestamp: date.timestamp });
        });
        chart.data.labels = labels;
        chart.data.datasets = getInitDataset(dates);
        return {
            charts: [chart],
            daysIndex: daysIndex
        }
    }

    function getDistanceDetailsCharts(data, device) {
        // CHART init
        const chart = deepcopy(CALIPER_CHART_DETAILS_TEMPLATE);
        chart.timeDurationY = false;
        chart.id = (Math.random() + 1).toString(36).substring(2);
        chart.name = i18n.t(chart.name);
        const dates = getDistanceDateColums(data, device);
        const labels = [];
        const daysIndex = [];
        dates.forEach(date => {
            labels.push(date.date);
            daysIndex.push({ date: date.date, timestamp: date.timestamp });
        });
        chart.data.labels = labels;
        chart.data.datasets = getInitDistanceDataset(dates);
        return {
            charts: [chart],
            daysIndex: daysIndex
        }
    }
    function changeUnit(text, unitName) {
        return unitParser.changeUnit(text, unitName, store.getters['user/getInternationalization'].unit, (text) => { return i18n.t(text) })
    }

    function getInitDistanceDataset(dates) {
        const result = [];
        const distanceDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        const calibratingDistanceDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        const inactiveDistanceDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        distanceDataset.label = changeUnit(i18n.t('caliperDetails.distance.chart.dataset.distanceLabel'), unitParser.UNITS_NAMES.DISTANCE);
        calibratingDistanceDataset.label = changeUnit(i18n.t('caliperDetails.distance.chart.dataset.calibratingDistanceLabel'), unitParser.UNITS_NAMES.DISTANCE);
        inactiveDistanceDataset.label = changeUnit(i18n.t('caliperDetails.distance.chart.dataset.inactiveDistanceLabel'), unitParser.UNITS_NAMES.DISTANCE);
        distanceDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_DISTANCE;
        calibratingDistanceDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_CALIBRATING_DISTANCE;
        inactiveDistanceDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_INACTIVE_DISTANCE;
        for (const date of dates) {
            let labelUnit = unitParser.parseDistance(date.distance, store.getters['user/getInternationalization'].unit, true);

            distanceDataset.data.push(unitParser.parseDistance(date.distance, store.getters['user/getInternationalization'].unit, false));
            distanceDataset.formattedLabelData.push(labelUnit);
            distanceDataset.formattedTooltipData.label.push(labelUnit)

            labelUnit = unitParser.parseDistance(date.calibratingDistance, store.getters['user/getInternationalization'].unit, true)
            calibratingDistanceDataset.data.push(unitParser.parseDistance(date.calibratingDistance || 0, store.getters['user/getInternationalization'].unit, false));
            calibratingDistanceDataset.formattedLabelData.push(labelUnit);
            calibratingDistanceDataset.formattedTooltipData.label.push(labelUnit);

            labelUnit = unitParser.parseDistance(date.inactiveDistance, store.getters['user/getInternationalization'].unit, true);
            inactiveDistanceDataset.data.push(unitParser.parseDistance(date.inactiveDistance, store.getters['user/getInternationalization'].unit, false));
            inactiveDistanceDataset.formattedLabelData.push(labelUnit);
            inactiveDistanceDataset.formattedTooltipData.label.push(labelUnit);
        }
        result.push(distanceDataset);
        result.push(calibratingDistanceDataset);
        result.push(inactiveDistanceDataset);
        return result;
    }

    function getInitDataset(dates) {
        const result = [];
        const inactiveOnMovingDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        const calibratingStopTimeDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        const calibratingOnMovingDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        inactiveOnMovingDataset.label = i18n.t('caliperDetails.chart.dataset.inactiveOnMovingLabel');
        calibratingStopTimeDataset.label = i18n.t('caliperDetails.chart.dataset.calibratingStopTimeLabel');
        calibratingOnMovingDataset.label = i18n.t('caliperDetails.chart.dataset.calibratingOnMovingLabel');
        inactiveOnMovingDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_TIME_INACTIVE;
        calibratingStopTimeDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_TIME_STOP;
        calibratingOnMovingDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_TIME_ON_MOVING;
        for (const date of dates) {
            inactiveOnMovingDataset.data.push(date.inactiveOnMoving);
            inactiveOnMovingDataset.formattedLabelData.push(parseSecondsToString(date.inactiveOnMoving));
            inactiveOnMovingDataset.formattedTooltipData.label.push(parseSecondsToString(date.inactiveOnMoving));

            calibratingStopTimeDataset.data.push(date.calibratingStopTime);
            calibratingStopTimeDataset.formattedLabelData.push(parseSecondsToString(date.calibratingStopTime));
            calibratingStopTimeDataset.formattedTooltipData.label.push(parseSecondsToString(date.calibratingStopTime));

            calibratingOnMovingDataset.data.push(date.calibratingOnMoving);
            calibratingOnMovingDataset.formattedLabelData.push(parseSecondsToString(date.calibratingOnMoving));
            calibratingOnMovingDataset.formattedTooltipData.label.push(parseSecondsToString(date.calibratingOnMoving));
        }
        result.push(inactiveOnMovingDataset);
        result.push(calibratingStopTimeDataset);
        result.push(calibratingOnMovingDataset);
        return result;
    }

    function getDateColums(thingDetails, device) {
        const result = [];
        if (thingDetails.detailsBuckets && thingDetails.detailsBuckets.length > 0) {
            let lastDate = null;
            thingDetails.detailsBuckets.forEach(bucketDetail => {
                const date = getDateData(result, bucketDetail.bucketDate);
                const pressureDetail = bucketDetail.pressureDetails && bucketDetail.pressureDetails.find((pressure) => { return pressure.hardwareNumber === device });
                if (pressureDetail) {
                    if (lastDate != date.date) {
                        date.inactiveOnMoving += pressureDetail.bucketInactiveTime;
                        date.calibratingStopTime += pressureDetail.bucketCalibratingStopTime;
                        date.calibratingOnMoving += pressureDetail.bucketCalibratingOnMoving;
                    } else {
                        date.inactiveOnMoving += pressureDetail.inactiveTime;
                        date.calibratingStopTime += pressureDetail.calibratingStopTime;
                        date.calibratingOnMoving += pressureDetail.calibratingOnMoving;
                    }
                }
                lastDate = date.date;
            });
        }
        return result;
    }

    function getDistanceDateColums(thingDetails, device) {
        const result = [];
        if (thingDetails.detailsBuckets && thingDetails.detailsBuckets.length > 0) {
            thingDetails.detailsBuckets.forEach(bucketDetail => {
                const date = getDateDistanceData(result, bucketDetail.bucketDate);
                const pressureDetail = bucketDetail.pressureDetails.find((pressure) => { return pressure.hardwareNumber === device });
                if (pressureDetail) {
                    date.distance += pressureDetail.distance;
                    date.calibratingDistance += pressureDetail.calibratingDistance;
                    date.inactiveDistance += pressureDetail.inactiveDistance;
                }
                date.distance = date.distance.toFixedNumber(2);
                date.calibratingDistance = date.calibratingDistance.toFixedNumber(2);
                date.inactiveDistance = date.inactiveDistance.toFixedNumber(2);
            });
        }
        return result;
    }

    function getDateDistanceData(dateList, bucketDate) {
        const timezone = store.getters["user/getEnterpriseTimezone"];
        const momentDate = moment(bucketDate).utcOffset(timezone);
        const dateString = parseDateWithTimezone(bucketDate / 1000, store.getters['user/getInternationalization'].dateMask).substring(0, 5);
        let data = dateList.find((dateObject) => { return dateObject.date === dateString });
        if (!data) {
            data = {
                date: dateString,
                timestamp: momentDate.milliseconds(0).minutes(0).seconds(0).hours(0).unix(),
                distance: 0,
                calibratingDistance: 0,
                inactiveDistance: 0
            };
            dateList.push(data);
        }
        return data;
    }

    function getDateData(dateList, bucketDate) {
        const timezone = store.getters["user/getEnterpriseTimezone"];
        const momentDate = moment(bucketDate).utcOffset(timezone);
        const dateString = parseDateWithTimezone(bucketDate / 1000, store.getters['user/getInternationalization'].dateMask).substring(0, 5);
        let data = dateList.find((dateObject) => { return dateObject.date === dateString });
        if (!data) {
            data = {
                date: dateString,
                timestamp: momentDate.milliseconds(0).minutes(0).seconds(0).hours(0).unix(),
                inactiveOnMoving: 0,
                calibratingStopTime: 0,
                calibratingOnMoving: 0
            };
            dateList.push(data);
        }
        return data;
    }

    async function generateMapPressure(thingDetails, thingId) {
        console.log(thingDetails.filters, thingDetails.filters)
        const mapDetails = await caliperApi.pressureThingDataByDate({ thingId, from: thingDetails.filters.mapDate.filterDates.from, to: thingDetails.filters.mapDate.filterDates.to });
        return await getPressureMap(thingId, thingDetails.hardware, mapDetails);
    }

    async function getPressureMap(idThing, hardwareNumber, positions) {
        const map = deepcopy(CALIPER_PRESSURE_MAP_JSON_TEMPLATE);
        map.id = Math.random() * 1000000000;
        const mapConfig = await configurationService.get("product.caliperDetails.pressure.map");
        const mapCurrentLayer = "Google Road";
        let initPosition = {
            lat: null,
            long: null,
            zoom: 12,
            currentLayer: mapCurrentLayer
        }
        if (mapConfig?.data?.currentLayer) {
            initPosition.currentLayer = mapConfig.data.currentLayer;
        }
        const alert1Reference = deepcopy(CALIPER_MAP_REFERENCE_TEMPLATE);
        const alert2Reference = deepcopy(CALIPER_MAP_REFERENCE_TEMPLATE);
        const alert3Reference = deepcopy(CALIPER_MAP_REFERENCE_TEMPLATE);
        const noAlertReference = deepcopy(CALIPER_MAP_REFERENCE_TEMPLATE);
        alert1Reference.name = "Alerta 1";
        alert1Reference.title = "Alerta 1";
        alert1Reference.color = ALERT_COLORS.ALERT_1;
        alert2Reference.name = "Alerta 2";
        alert2Reference.title = "Alerta 2";
        alert2Reference.color = ALERT_COLORS.ALERT_2;
        alert3Reference.name = "Alerta 3";
        alert3Reference.title = "Alerta 3";
        alert3Reference.color = ALERT_COLORS.ALERT_3;
        noAlertReference.name = "Sin Alerta";
        noAlertReference.title = "Sin Alerta";
        noAlertReference.color = ALERT_COLORS.NO_ALERT;
        map.groups[0].references.push(alert1Reference);
        map.groups[0].references.push(alert2Reference);
        map.groups[0].references.push(alert3Reference);
        map.groups[0].references.push(noAlertReference);
        map.groups[0].name = i18n.t('caliperDetails.pressure.map.name');
        map.groups[0].title = i18n.t('caliperDetails.pressure.map.title');
        let lastAlert = null;
        if (positions && positions.length > 0) {
            for (const position of positions) {
                const caliperPoint = deepcopy(CALIPER_MAP_POINT_JSON_TEMPALTE);
                caliperPoint.id = idThing;
                // caliperPoint.marker.title = getMapTitle(position, hardwareNumber);
                // getMapTitle(position, hardwareNumber);
                caliperPoint.lat = position.TELE_lat;
                caliperPoint.lng = position.TELE_long;
                caliperPoint.value = getMapReferenceByColor(position.states, hardwareNumber);
                map.groups[0].series[0].points.push(caliperPoint);
                const currentAlert = getAlertType(position, hardwareNumber)
                if (currentAlert != lastAlert) {
                    const caliperMarkPoint = deepcopy(CALIPER_DETAIL_MARK_MAP_POINT_JSON_TEMPALTE);
                    caliperMarkPoint.id = idThing;
                    caliperMarkPoint.lat = position.TELE_lat;
                    caliperMarkPoint.lng = position.TELE_long;
                    caliperMarkPoint.marker.title = getMapTitle(position, hardwareNumber);
                    caliperMarkPoint.value = getMapReferenceByColor(position.states, hardwareNumber);
                    map.groups[0].series[1].points.push(caliperMarkPoint);
                    lastAlert = currentAlert;
                }
                // initPosition.lat = position.TELE_lat;
                // initPosition.long = position.TELE_long;
            }
        }
        /* if (!initPosition.lat) {
            initPosition = null;
        } */
        return {
            maps: [map],
            initPosition
        };
    }

    function getAlertType(track, hardwareNumber) {
        if (track.states[hardwareNumber]) {
            return track.states[hardwareNumber];
        }
        return 'NoAlert';
    }

    function getMapTitle(track, hardwareNumber) {
        const speed = unitParser.parseSpeed(track.TELE_speed_KPH || 0, store.getters['user/getInternationalization'].unit, true);
        const psi = unitParser.parsePressure(track.hardwarePsi[hardwareNumber] || 0, store.getters['user/getInternationalization'].unit, true);
        const pressureIn = unitParser.parsePressure(track.pressureIn || 0, store.getters['user/getInternationalization'].unit, true);
        const dateString = parseDateWithTimezone(track.trackTimestamp, store.getters['user/getInternationalization'].dateMask);
        const timeString = parseTimeWithTimezone(track.trackTimestamp, store.getters['user/getInternationalization'].date);
        const alertLevel = i18n.t("caliperDetails.pressure.map.tooltip.alerts." + (track.states[hardwareNumber] || "NO_ALERT"));
        return `${i18n.t('caliperDetails.pressure.map.tooltip.date')}: ${dateString} ${timeString} ` +
            `<br>${i18n.t('caliperDetails.pressure.map.tooltip.pressureIn')}: ${pressureIn}` +
            `<br>${i18n.t('caliperDetails.pressure.map.tooltip.psi')}: ${psi}` +
            `<br>${i18n.t('caliperDetails.pressure.map.tooltip.speed')}: ${speed}` +
            `<br>${i18n.t('caliperDetails.pressure.map.tooltip.alertLevel')}: ${alertLevel}<br>`;
    }

    function getMapReferenceByColor(states, hardwareNumber) {
        if (states[hardwareNumber]) {
            switch (states[hardwareNumber]) {
                case 'ALERT_1':
                    return 'Alerta 1';
                case 'ALERT_2':
                    return 'Alerta 2';
                case 'ALERT_3':
                    return 'Alerta 3';
            }
        }
        return "Sin Alerta";
    }

    async function generateChartPressure(thingDetails, filters, thingId, device, caliperConfig) {
        if (!thingDetails.data.pressureDetails) {
            // thingDetails.data.pressureDetails = await caliperApi.pressureThingDataByDate({ thingId, from: thingDetails.filters.mapDate.filterDates.from, to: thingDetails.filters.mapDate.filterDates.to });
            // thingDetails.data.pressureDetails = await caliperApi.pressureThingDataByDate({ thingId, from: filters.dates.from, to: filters.dates.to });
        }
        const reportData = await caliperApi.pressureThingDataByDate({ thingId, from: thingDetails.filters.mapDate.filterDates.from, to: thingDetails.filters.mapDate.filterDates.to });
        // const reportData = thingDetails.data.pressureDetails;
        let chart = deepcopy(CALIPER_DETAILS_CHART_PRESSURE);
        let serie = chart.series.find(x => x.name === i18n.t('caliperDetails.pressure.labels.speed'));
        serie.name = serie.name + `[${unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.SPEED)}]`;
        let seriesPressure = chart.series.filter(x => x.name !== serie.name);
        seriesPressure.forEach(element => {
            element.name = element.name + `[${unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.PRESSURE)}]`;
        });

        if (store.getters['user/getInternationalization'].date === unitParser.INTERNATIONALIZATION_TIME["12_HOURS"]) {
            let iDateTimeFormat = {
                labels: {
                    format: '{value:%I:%M%P}'
                }
            }
            chart.xAxis = {
                ...iDateTimeFormat
            }
            iDateTimeFormat = {
                headerFormat: '{point.x:%A %b %e, %I:%M%P}',
            }
            chart.tooltip = {
                ...iDateTimeFormat
            }
        }

        const series = getSeriesPressure(reportData, device, caliperConfig);

        chart.series[0].data = processSeriesPressureDate(series.stateRangeSerie);
        chart.series[1].data = processSeriesPressureDate(series.speed);
        chart.series[2].data = processSeriesPressureDate(series.pressure);
        chart.series[3].data = processSeriesPressureDate(series.pressureIn);
        chart.series[4].data = processSeriesPressureDate(series.adjustment);


        return chart;
    }

    function processSeriesPressureDate(data) {

        let timezone = store.getters["user/getEnterpriseTimezone"];

        data.forEach(element => {
            let modifiedDate = ((element[0]) + (3600000 * timezone));
            element[0] = modifiedDate;
        })

        return data;

    }


    function getFromToDate(filters) {
        const result = {
            from: 0,
            to: 0
        }
        if (filters.dateData.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
            const temp = {};
            temp.sinceDate = filters.dateData.sinceDate;
            temp.toDate = filters.dateData.toDate;
            if (filters.dateData.selectedDateAndTimeCustomRangeType === 'date') {
                temp.sinceTime = '00:00';
                temp.toTime = '00:00';
            } else {
                temp.sinceTime = filters.dateData.sinceTime;
                temp.toTime = filters.dateData.toTime;
            }
            result.from = new Date(`${temp.sinceDate} ${temp.sinceTime}`).getTime() / 1000;
            result.to = new Date(`${temp.toDate} ${temp.toTime}`).getTime() / 1000;
        } else {
            let from = null;
            let to = moment();
            let resetFromMinutes = true;
            switch (filters.dateData.selectedDateAndTimeRange) {
                case "lastTwoMonths":
                    from = moment().startOf("month").subtract(1, "months");
                    break;
                case "currentHour":
                    from = moment().milliseconds(0).minutes(0).seconds(0);
                    break;
                case "lastHour":
                    from = moment().subtract(1, "hours");
                    to = to.milliseconds(0).minutes(0).seconds(0);
                    break;
                case "lastSixHour":
                    from = moment().subtract(6, "hours");
                    break;
                case "today":
                    from = moment().startOf("day");
                    break;
                case "yesterday":
                    from = moment().subtract(1, "days").startOf("day");
                    break;
                case "currentWeek":
                    from = moment().startOf("week");
                    break;
                case "lastWeek":
                    from = moment().subtract(1, "weeks").startOf("week");
                    break;
                case "lastTwoWeeks":
                    from = moment().subtract(2, "weeks").startOf("week");
                    break;
                case "currentMonth":
                    from = moment().startOf("month");
                    break;
                case "t24Hs":
                    from = moment().subtract(1, "days");
                    resetFromMinutes = false;
                    break;
                case "t48Hs":
                    from = moment().subtract(48, "hours");
                    resetFromMinutes = false;
                    break;
                case "t72Hs":
                    from = moment().subtract(3, "days");
                    resetFromMinutes = false;
                    break;
                default:
                    break;
            }
            result.from = from.unix();
            if (resetFromMinutes) {
                result.from = from.milliseconds(0).minutes(0).seconds(0).unix();
            }
            result.to = to.unix();
        }

        return result;
    }

    function getSeriesPressure(reportData, device, caliperConfig) {
        const stateRangeSerie = [];
        const speed = [];
        const pressure = [];
        const pressureIn = [];
        const adjustment = [];
        const adjustmentConfig = getAdjustmentConfig(caliperConfig);
        const result = {
            stateRangeSerie,
            speed,
            pressure,
            pressureIn,
            adjustment
        };
        if (reportData && reportData.length > 0) {
            for (const track of reportData) {
                if (track.TELE_caliper_state === '00' || track.TELE_caliper_state === '01' || track.TELE_caliper_state === '--' || !track.TELE_caliper_state) {
                    const timestamp = track.trackTimestamp * 1000;
                    stateRangeSerie.push([
                        timestamp,
                        Number(unitParser.parsePressure(adjustmentConfig[device][track.TELE_caliper_state || 'base'][0], store.getters['user/getInternationalization'].unit, false)),
                        Number(unitParser.parsePressure(adjustmentConfig[device][track.TELE_caliper_state || 'base'][1], store.getters['user/getInternationalization'].unit, false))
                    ]);
                    speed.push([
                        timestamp,
                        Math.round(unitParser.parseSpeed(track.TELE_speed_KPH || 0, store.getters['user/getInternationalization'].unit))
                    ]);
                    pressure.push([
                        timestamp,
                        Number(unitParser.parsePressure(track.hardwarePsi[device] || 0, store.getters['user/getInternationalization'].unit, false))
                    ]);
                    pressureIn.push([
                        timestamp,
                        Number(unitParser.parsePressure(track.pressureIn || 0, store.getters['user/getInternationalization'].unit, false))
                    ]);
                    adjustment.push([
                        timestamp,
                        Number(unitParser.parsePressure(adjustmentConfig[device][track.TELE_caliper_state || 'base'][0], store.getters['user/getInternationalization'].unit, false))
                    ]);
                }
            }
        }
        return result;
    }

    function getAdjustmentConfig(caliperConfig) {
        const adjustmentConfig = (caliperConfig && caliperConfig.pressure && caliperConfig.pressure.hardwareNumber) || {};
        return {
            '01': getAdjustmentByHardwareNumber(adjustmentConfig, '01'),
            '02': getAdjustmentByHardwareNumber(adjustmentConfig, '02'),
            '03': getAdjustmentByHardwareNumber(adjustmentConfig, '03'),
            '04': getAdjustmentByHardwareNumber(adjustmentConfig, '04'),
            '05': getAdjustmentByHardwareNumber(adjustmentConfig, '05'),
            '06': getAdjustmentByHardwareNumber(adjustmentConfig, '06')
        }
    }

    function getAdjustmentByHardwareNumber(config, hardwareNumber) {
        if (config[hardwareNumber]) {
            const baseConfig = config[hardwareNumber]['base'] || ADJUSTMENT_DEFAULT_CONFIG;
            const lowConfig = config[hardwareNumber]['low'] || baseConfig;
            const highConfig = config[hardwareNumber]['hight'] || baseConfig;
            return {
                '00': [lowConfig, lowConfig + lowConfig * 0.18],
                '01': [highConfig, highConfig + highConfig * 0.18],
                '02': [highConfig, highConfig + highConfig * 0.18],
                '03': [lowConfig, lowConfig + lowConfig * 0.18],
                'base': [baseConfig, baseConfig + baseConfig * 0.18],
                '--': [baseConfig, baseConfig + baseConfig * 0.18]
            }
        } else {
            return {
                '00': [ADJUSTMENT_DEFAULT_CONFIG, ADJUSTMENT_DEFAULT_CONFIG + ADJUSTMENT_DEFAULT_CONFIG * 0.18],
                '01': [ADJUSTMENT_DEFAULT_CONFIG, ADJUSTMENT_DEFAULT_CONFIG + ADJUSTMENT_DEFAULT_CONFIG * 0.18],
                '02': [ADJUSTMENT_DEFAULT_CONFIG, ADJUSTMENT_DEFAULT_CONFIG + ADJUSTMENT_DEFAULT_CONFIG * 0.18],
                '03': [ADJUSTMENT_DEFAULT_CONFIG, ADJUSTMENT_DEFAULT_CONFIG + ADJUSTMENT_DEFAULT_CONFIG * 0.18],
                'base': [ADJUSTMENT_DEFAULT_CONFIG, ADJUSTMENT_DEFAULT_CONFIG + ADJUSTMENT_DEFAULT_CONFIG * 0.18],
                '--': [ADJUSTMENT_DEFAULT_CONFIG, ADJUSTMENT_DEFAULT_CONFIG + ADJUSTMENT_DEFAULT_CONFIG * 0.18]
            }
        }
    }

    function loadThingSelector(data, selectedItem) {
        store.dispatch("breadcrumb/setEntitySelectorConfiguration", {
            key: "idThing",
            config: {
                name: i18n.t("equipments"),
                value: "idThing",
                text: "thingName"
            }
        });
        const items = data.map(element => ({
            key: 'idThing',
            idThing: element.idThing,
            thingName: element.thingName
        }));
        store.dispatch("breadcrumb/setEntitySelectorItems", {
            key: 'idThing',
            items: items
        });
        const item = {
            key: 'idThing',
            idThing: selectedItem.idThing,
            thingName: selectedItem.thingName
        };
        store.dispatch("breadcrumb/setSelectedItem", {
            idThing: item
        });
    }

    function loadHardwareSelector(hardwareList, idThing, selectedItem) {
        const selectors = hardwareList[idThing] || [];
        store.dispatch("breadcrumb/setEntitySelectorConfiguration", {
            key: "hardwareId",
            config: {
                name: i18n.t("Hardware"),
                value: "hardwareId",
                text: "hardwareName"
            }
        });
        updateHardwareSelector(selectors, selectedItem);
    }

    function updateHardwareSelector(selectors, selectedItem) {
        const items = selectors.map(element => ({
            key: 'hardwareId',
            hardwareId: element,
            hardwareName: i18n.t('caliperDetails.filters.hardware.' + element)
        }));
        store.dispatch("breadcrumb/setEntitySelectorItems", {
            key: "hardwareId",
            items: items
        });
        const item = {
            key: 'hardwareId',
            hardwareId: selectedItem.hardwareId,
            hardwareName: selectedItem.hardwareName
        };
        store.dispatch("breadcrumb/setSelectedItem", {
            hardwareId: item
        });
    }

    function parseSecondsToString(time, hoursChart = false) {
        const hours = parseInt(time / 3600);
        let minutes = parseInt((time % 3600) / 60);
        const seconds = parseInt(time % 60);
        if (hoursChart) {
            if (time == 3600) minutes = 60;
            return `${minutes}m ${seconds}s`;
        }
        return `${hours}h ${minutes}m ${seconds}s`;
    }

    function setDetailsChartOptions(chart) {
        chart.options.scales.yAxes[0].beforeTickToLabelConversion = function (context) {
            context.options.ticks.callback = secondsToString
        }
        chart.options.tooltips.callbacks = {
            label: function (context) {
                let labelType = null;
                if (context.datasetIndex === 0) {
                    labelType = "inactiveOnMovingLabel";
                }
                if (context.datasetIndex === 1) {
                    labelType = "calibratingStopTimeLabel";
                }
                if (context.datasetIndex === 2) {
                    labelType = "calibratingOnMovingLabel";
                }
                const label = i18n.t('caliperDetails.chart.dataset.' + labelType);
                return label + ": " + toolsService.parseSecondsToString(context.value);
            }
        }
    }

    function getDetailsHoursChart(data, device, day) {
        // CHART init
        const chart = deepcopy(CALIPER_CHART_DETAILS_TEMPLATE);
        chart.id = (Math.random() + 1).toString(36).substring(2);
        chart.name = 'caliperDetails.chart.titleHours';
        setDetailsChartOptions(chart)
        chart.options.scales.yAxes[0].ticks.stepSize = 300;
        chart.name = i18n.t(chart.name);
        const dates = getDetailsHourColums(data, device, day);
        chart.data.labels = dates.map(date => date.hour);
        chart.data.datasets = getInitDetailsHoursDataset(dates);
        return {
            charts: [chart],
            dates: dates
        };
    }

    function getDistanceDetailsHoursChart(data, device, day) {
        // CHART init
        const chart = deepcopy(CALIPER_CHART_DETAILS_TEMPLATE);
        chart.timeDurationY = false;
        chart.id = (Math.random() + 1).toString(36).substring(2);
        chart.name = 'caliperDetails.chart.titleHours';
        chart.name = i18n.t(chart.name);
        const dates = getDetailsHourDistanceColums(data, device, day);
        chart.data.labels = dates.map(date => date.hour);
        chart.data.datasets = getInitDistanceDataset(dates);
        chart.data.datasets[0].label = i18n.t('caliperDetails.distance.chart.dataset.hourDistanceLabel');
        chart.data.datasets[1].label = i18n.t('caliperDetails.distance.chart.dataset.hourCalibratingDistanceLabel');
        chart.data.datasets[2].label = i18n.t('caliperDetails.distance.chart.dataset.hourInactiveDistanceLabel');
        return {
            charts: [chart],
            dates: dates
        };
    }

    function getInitDetailsHoursDataset(dates) {
        const result = [];
        const inactiveOnMovingDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        const calibratingStopTimeDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        const calibratingOnMovingDataset = deepcopy(CALIPER_CHART_DATASET_TEMPLATE);
        inactiveOnMovingDataset.label = i18n.t('caliperDetails.chart.dataset.inactiveOnMovingLabel');
        calibratingStopTimeDataset.label = i18n.t('caliperDetails.chart.dataset.calibratingStopTimeLabel');
        calibratingOnMovingDataset.label = i18n.t('caliperDetails.chart.dataset.calibratingOnMovingLabel');
        inactiveOnMovingDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_TIME_INACTIVE;
        calibratingStopTimeDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_TIME_STOP;
        calibratingOnMovingDataset.backgroundColor = CHART_TYPE_COLORS.DETAILS_TIME_ON_MOVING;
        for (const date of dates) {
            inactiveOnMovingDataset.data.push(date.inactiveOnMoving);
            inactiveOnMovingDataset.formattedLabelData.push(parseSecondsToString(date.inactiveOnMoving, true));
            inactiveOnMovingDataset.formattedTooltipData.label.push(parseSecondsToString(date.inactiveOnMoving, true));

            calibratingStopTimeDataset.data.push(date.calibratingStopTime);
            calibratingStopTimeDataset.formattedLabelData.push(parseSecondsToString(date.calibratingStopTime, true));
            calibratingStopTimeDataset.formattedTooltipData.label.push(parseSecondsToString(date.calibratingStopTime, true));

            calibratingOnMovingDataset.data.push(date.calibratingOnMoving);
            calibratingOnMovingDataset.formattedLabelData.push(parseSecondsToString(date.calibratingOnMoving, true));
            calibratingOnMovingDataset.formattedTooltipData.label.push(parseSecondsToString(date.calibratingOnMoving, true));
        }
        result.push(inactiveOnMovingDataset);
        result.push(calibratingStopTimeDataset);
        result.push(calibratingOnMovingDataset);
        return result;
    }
    function getInternationalizationHour(hour) {
        if (store.getters['user/getInternationalization'].date === unitParser.INTERNATIONALIZATION_TIME["12_HOURS"]) {
            let AmOrPm = hour >= 12 ? 'pm' : 'am';
            hour = (hour % 12) || 12;
            return hour + AmOrPm
        }
        return hour
    }
    function getDetailsHourColums(thingDetails, device, day) {
        const result = [];
        for (let i = 0; i <= 23; i++) {
            result.push({
                hour: getInternationalizationHour(i),
                timestamp: day.timestamp + (i * 3600),
                inactiveOnMoving: 0,
                calibratingStopTime: 0,
                calibratingOnMoving: 0
            });
        }
        if (thingDetails.detailsBuckets && thingDetails.detailsBuckets.length > 0) {
            thingDetails.detailsBuckets.forEach(bucketDetail => {
                const timezone = store.getters["user/getEnterpriseTimezone"];
                const momentDate = moment(bucketDetail.bucketDate).utcOffset(timezone);
                const dateString = parseDateWithTimezone(bucketDetail.bucketDate / 1000, store.getters['user/getInternationalization'].dateMask).substring(0, 5);
                if (dateString === day.date) {
                    const pressureDetail = bucketDetail.pressureDetails.find((pressure) => { return pressure.hardwareNumber === device });
                    const dateString = momentDate.format('HH');
                    const hourIndex = parseInt(dateString);
                    if (pressureDetail) {
                        result[hourIndex].inactiveOnMoving += pressureDetail.bucketInactiveTime;
                        result[hourIndex].calibratingStopTime += pressureDetail.bucketCalibratingStopTime;
                        result[hourIndex].calibratingOnMoving += pressureDetail.bucketCalibratingOnMoving;
                    }
                }
            });
        }
        return result;
    }

    function getDetailsHourDistanceColums(thingDetails, device, day) {
        const result = [];
        for (let i = 0; i <= 23; i++) {
            result.push({
                hour: getInternationalizationHour(i),
                timestamp: day.timestamp + (i * 3600),
                distance: 0,
                calibratingDistance: 0,
                inactiveDistance: 0
            });
        }
        if (thingDetails.detailsBuckets && thingDetails.detailsBuckets.length > 0) {
            thingDetails.detailsBuckets.forEach(bucketDetail => {
                const timezone = store.getters["user/getEnterpriseTimezone"];
                const momentDate = moment(bucketDetail.bucketDate).utcOffset(timezone);
                const dateString = parseDateWithTimezone(bucketDetail.bucketDate / 1000, store.getters['user/getInternationalization'].dateMask).substring(0, 5);
                if (dateString === day.date) {
                    const pressureDetail = bucketDetail.pressureDetails.find((pressure) => { return pressure.hardwareNumber === device });
                    const dateString = momentDate.format('HH');
                    const hourIndex = parseInt(dateString);
                    if (pressureDetail) {
                        result[hourIndex].distance += pressureDetail.distance.toFixedNumber(2);
                        result[hourIndex].calibratingDistance += pressureDetail.calibratingDistance.toFixedNumber(2);
                        result[hourIndex].inactiveDistance += pressureDetail.inactiveDistance.toFixedNumber(2);
                    }
                }
            });
        }
        return result;
    }

    function getDaysByFromTo(from, to) {
        const result = [];
        for (; from < to;) {
            let toMoment = moment(from * 1000).add(7, 'days').endOf('day');
            toMoment = toMoment.unix() > to ? moment(to * 1000).endOf('day') : toMoment;
            const toString = unitParser.parseDate(toMoment.unix(), store.getters['user/getInternationalization'].dateMask).substring(0, 5);
            result.push({
                key: "daysSelector",
                date: unitParser.parseDate(from, store.getters['user/getInternationalization'].dateMask).substring(0, 5) + " -> " + toString,
                filterDates: { from: from, to: toMoment.unix() }
            });
            from = toMoment.unix() + 1;
        }
        if (result.length > 0) {
            result[result.length - 1].to = to;
        }
        return result;
    }

    function timestampToString(timestamp) {
        var date = new Date(timestamp * 1000);
        const timezone = store.getters["user/getEnterpriseTimezone"];
        var formattedDate = moment(date).utcOffset(timezone).locale(localStorage.getItem('locale')).format('ll');
        return `${formattedDate} ${parseTimeWithTimezone(timestamp, store.getters['user/getInternationalization'].date)}`
    }

    return {
        generateDetailsReport,
        generateChartPressure,
        loadHardwareSelector,
        loadThingSelector,
        getDetailsHoursChart,
        getHoursMap,
        initReport,
        getDetailsReport,
        generateDistanceDetailsReport,
        getDistanceDetailsCharts,
        getDistanceDetailsHoursChart,
        getHoursDistanceMap,
        getReportByKey,
        getFromToDate,
        generateMapPressure,
        getDaysByFromTo,
        updateHardwareSelector
    }
})();
