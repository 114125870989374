import i18n from "@/i18n";
import { CellType, DataType, FilterType } from '@colven/common-domain-lib/lib';


export const SUMMARY_REPORT_CHART_FILTERS = [
    [0, 7200],
    [7200, 14400],
    [14400, Number.MAX_VALUE]
]

export const CALIPER_REPORT_TYPES = {
    SUMMARY: "CALIPER_SUMMARY_REPORT",
    DETAILS: "CALIPER_DETAILS_REPORT"
}

export const CALIPER_CHART_SUMMARY_TEMPLATE = {
    type: "bar",
    name: "caliperSummary.chart.title",
    data: {
        labels: [],
        datasets: []
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
            xAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        tooltips: {},
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    mode: "xy",
                },
                zoom: {
                    enabled: true,
                    mode: "xy",
                },
            },
        },
    }
};

export const CALIPER_CHART_DETAILS_TEMPLATE = {
    type: "bar",
    name: "caliperDetails.chart.titleDays",
    data: {
        labels: [],
        datasets: []
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        timeFormat: 'HH_MM_SS',
        scales: {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    },
                },
            ],
        },
        tooltips: {},
        plugins: {
            zoom: {
                pan: {
                    enabled: true,
                    mode: "xy",
                },
                zoom: {
                    enabled: true,
                    mode: "xy",
                },
            },
        },
    }
};

export const CALIPER_CHART_DATASET_TEMPLATE = {
    label: "caliperSummary.chart.dataset.label",
    data: [],
    backgroundColor: [],
    type: undefined,
    dataProperty: undefined,
    originalData: [],
    formattedTooltipData: {
        label: []
    },
    formattedLabelData: [],
    fill: true,
    yAxisID: undefined,
    lineTension: 0
};

export const CHART_TYPE_COLORS = {
    FIRST: "#17A612",
    SECOND: "#F2FF42",
    THIRD: "#FD0000",
    DETAILS_TIME_ON_MOVING: "#F9FF3A",
    DETAILS_TIME_STOP: "#D4910B",
    DETAILS_TIME_INACTIVE: "#E80000",
    DETAILS_DISTANCE: "#0FA205",
    DETAILS_INACTIVE_DISTANCE: "#E81700",
    DETAILS_CALIBRATING_DISTANCE: "#FFF700"
}

export const CALIPER_CHART_SUMMARY_LABELS = [
    'caliperSummary.chart.calibrating.hours.lessTwo',
    'caliperSummary.chart.calibrating.hours.twoFour',
    'caliperSummary.chart.calibrating.hours.greatFour',
]

export const CALIPER_TABLE_HEADERS = [
    {
        text: i18n.t("caliperSummary.table.nameThing"),
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "thingName",
        width: 220,
    },
    {
        text: i18n.t("caliperSummary.table.calibratingTime"),
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "calibratingTimeString",
        width: 150,
        cellType: CellType.textColor,
        cellConfig: {
            color: 'calibrationTimeColor',
        }
    },
    {
        text: i18n.t("caliperSummary.table.state"),
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "state",
        width: 90
    },
    {
        text: i18n.t("caliperSummary.table.changesCount"),
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "changesCount",
        width: 150,
    },
    {
        text: i18n.t("caliperSummary.table.trend"),
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "trend",
        width: 110,
    },
    {
        text: i18n.t("caliperSummary.table.calibratingDistance"),
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "calibratingDistance",
        width: 200,
    },
    {
        text: i18n.t("caliperSummary.table.inactiveDistance"),
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "inactiveDistance",
        width: 220,
    },
    {
        text: i18n.t("caliperSummary.table.distance"),
        align: "left",
        filterType: FilterType.textField,
        selected: true,
        sortable: true,
        type: DataType.string,
        value: "distance",
        width: 220,
    }
];

export const CALIPER_SUMMARY_MAP_JSON_TEMPLATE = {
    name: i18n.t("caliperSummary.map.name"),
    groups: [
        {
            name: i18n.t("caliperSummary.map.groupName"),
            title: i18n.t("caliperSummary.map.groupTitle"),
            measure: undefined,
            referenceByInterval: false,
            series: [
                {
                    type: "MARKERS",
                    points: [],
                },
            ],
            references: [],
            exclusive: true,
            displayOnStart: true,
        },
    ],
    filters: [],
    info: {},
};

export const CALIPER_PRESSURE_MAP_JSON_TEMPLATE = {
    name: i18n.t("caliperDetails.pressure.map.name"),
    groups: [
        {
            name: i18n.t("caliperDetails.pressure.map.groupName"),
            title: i18n.t("caliperDetails.pressure.map.groupTitle"),
            measure: undefined,
            referenceByInterval: false,
            series: [
                {
                    type: "LINE",
                    pattern: {
                        type: "DIRECTION",
                    },
                    points: [],
                },
                {
                    type: "MARKERS",
                    points: [],
                }
            ],
            references: [],
            exclusive: true,
            displayOnStart: true,
        },
    ],
    filters: [],
    info: {},
};

export const CALIPER_MAP_JSON_TEMPLATE = {
    name: i18n.t("caliperSummary.map.name"),
    groups: [
        {
            name: i18n.t("caliperSummary.map.groupName"),
            title: i18n.t("caliperSummary.map.groupTitle"),
            measure: undefined,
            referenceByInterval: false,
            series: [
                {
                    type: "LINE",
                    points: [],
                },
            ],
            references: [],
            exclusive: true,
            displayOnStart: true,
        },
    ],
    filters: [],
    info: {},
};

export const CALIPER_MAP_REFERENCE_TEMPLATE = {
    name: null,
    color: "rgb(0,176,240)",
    isInterval: false,
    title: "asdf",
    weight: 5
};

export const CALIPER_SUMMARY_MAP_POINT_JSON_TEMPALTE = {
    id: null,
    lat: null,
    lng: null,
    value: "TRUCK",
    filters: [],
    marker: {
        title: "",
        type: "img",
        ref: "truck-icon.svg",
        size: 40,
        tooltipDirection: "right",
        tooltipPermanent: false,
        tooltipBorderColor: "rgb(0,176,240)",
        businessData: {
            number: 0,
            direction: "northeast",
            stopped: false,
        },
    },
}

export const CALIPER_DETAIL_MARK_MAP_POINT_JSON_TEMPALTE = {
    id: null,
    lat: null,
    lng: null,
    value: "TRUCK",
    filters: [],
    marker: {
        title: "test",
        type: "img1",
        ref: "truck-icon.svg",
        size: 40,
        tooltipDirection: "right",
        tooltipPermanent: false,
        tooltipBorderColor: "rgb(0,176,240)"
    },
}

export const CALIPER_MAP_POINT_JSON_TEMPALTE = {
    id: null,
    lat: null,
    lng: null,
    type: "LINE",
    value: "TRUCK",
    filters: []
}

export const CALIBRATING_TIME_COLORS = {
    ZERO_TWO_HOURS: "#17A612",
    TWO_FOUR_HOURS: "#E8C141",
    MORE_FOUR_HOURS: "#FD0000"
}

export const TRENDS_STATES_ICONS = {
    UP: '⬆',
    DOWN: '⬇',
    NC: '-'
}

export const CALIPER_DETAILS_CHART_PRESSURE = {
    // title: { text: 'Title' },
    xAxis: { type: 'datetime' },
    yAxis: {
        title: { text: null }
    },
    chart: {
        renderTo: 'HighchartComponentId_' + (Math.random() + 1).toString(36).substring(7),
        reflow: true
    },
    tooltip:{
        valueDecimals: 2
    },
    legend: {
        enabled: true,
        verticalAlign: 'top'
    },
    exporting: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    lang: {
        noData: 'Sin datos'
    },
    series: [
        {
            name: 'Range',
            data: null,
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            color: "#0DC626",//Highcharts.getOptions().colors[0],
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
                enabled: false
            },
            label: {
                enabled: false
            },
            enableMouseTracking: false
        },
        {
            name: i18n.t('caliperDetails.pressure.labels.speed'),
            data: null,
            zIndex: 1,
            showInLegend: true,
            marker: {
                fillColor: 'white',
                lineWidth: 2,
                lineColor: "blue"
            },
            color: "#9ee368"
        },
        {
            name: i18n.t('caliperDetails.pressure.labels.pressure'),
            data: null,
            zIndex: 1,
            showInLegend: true,
            marker: {
                fillColor: 'white',
                lineWidth: 2,
                lineColor: "#27A600",
                symbol: 'circle'
            },
            color: "#0518ED"
        },
        {
            name: i18n.t('caliperDetails.pressure.labels.pressureIn'),
            data: null,
            zIndex: 1,
            showInLegend: true,
            marker: {
                fillColor: 'white',
                lineWidth: 1,
                lineColor: "black"
            },
            color: "black"
        },
        {
            name: i18n.t('caliperDetails.pressure.labels.adjustment'),
            data: null,
            zIndex: 1,
            showInLegend: true,
            marker: {
                fillColor: 'red',
                lineWidth: 2,
                lineColor: "red"
            },
            color: "red"
        }
    ]
}

export const ALERT_COLORS = {
    ALERT_1: "#00bbff",
    ALERT_2: "#fff705",
    ALERT_3: "#ed0e0e",
    NO_ALERT: "#08cc33"
}

export const ADJUSTMENT_DEFAULT_CONFIG = 10;